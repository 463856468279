import React, {useState} from 'react';
import { bool, any } from 'prop-types';
import VSensor from "react-visibility-sensor";

import "./animations-on-scroll.scss";

const VisibilitySensor = ({ once, children, ...props }) => {

  const [State, setState] = useState({visible: false });

  const onChangeVisibility = isActive => {
    setState({ ...State, visible: isActive });
}

  return (
    <VSensor
      partialVisibility
      onChange={e => onChangeVisibility(e)} active={!State.visible}
    >
      {({ isVisible }) => (children({ isVisible }))}
    </VSensor>
  )
}

VisibilitySensor.propTypes = {
  once: bool,
  children: any.isRequired,
};

VisibilitySensor.defaultProps = {
  once: false
};

export default VisibilitySensor;
